<template>
  <div class="page">
    <div class="c-emailsent c-emailsent_h-800">
      <div class="c-emailsent__body c-emailsent__body_top-208">
        <img src = "@/assets/images/email-check.svg" alt="email-check">
        <h2 class="c-emailsent__title">New API Key <span class="c-emailsent__title_black">Created</span></h2>
        <p class="c-emailsent__content c-emailsent__content-apikeyconfirm">You will be downloading the API keys shortly, please ensure it is kept safe. If you lose the API keys, log in to your account and delete the access for the key immediately.</p>
        <a class="c-emailsent__link" @click='routerRedirect("/signin")'>Return to Home >></a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'NewAPIKeyCreated',
  components: {
  },
  mounted:function(){
    var api = localStorage.getItem('api-key');
    localStorage.removeItem('api-key');
    if(api!=null){
        var filename = "api.pem";
        var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(api));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  },
  data() {
    return {
    }
  },
}
</script>
